import { API } from '@/BeatoutAPI';
import { helpers } from '../../helpers';
import { Settings } from '@/types';
import { settingsModuleActionContext } from '.';
const actions = {
    async get(context) {
        const { commit } = settingsModuleActionContext(context);
        const response = await API.getRequest().get('settings/general');
        const settings = new Settings(response.data);
        commit.set(settings);
    },
    async update(context, settings) {
        const { dispatch } = settingsModuleActionContext(context);
        await API.getRequest().put('settings/general', JSON.stringify(settings));
        await dispatch.get();
    },
    async subscribe(context) {
        const { dispatch } = settingsModuleActionContext(context);
        return helpers.subscribe('users', { repeat: 30000 }, async () => {
            dispatch.get();
        });
    },
    async unsubscribe(_context) {
        return helpers.unsubscribe('users');
    }
};
export default actions;
