export class SensorAction {
    constructor(data = undefined) {
        this.sensor = '';
        this.action = '';
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('SensorAction load: data is not an object');
        this.sensor = typeof data.sensor === 'string' ? data.sensor : '';
        this.action = typeof data.action === 'string' ? data.action : '';
    }
}
