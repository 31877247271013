import { helpers } from '../../helpers';
const updateSingleState = (state, stateKey, value) => {
    // @ts-ignore
    if (value !== undefined && !helpers.compareObjects(state[stateKey], value)) {
        // @ts-ignore
        state[stateKey] = value; // TOMC: REACTIVE
    }
};
export default {
    setConfigs(state, rooms) {
        // @ts-ignore
        helpers.updateStateToObject(state.config, rooms, '_id');
    },
    setConfig(state, room) {
        if (room._id) {
            if (state.config[room._id] === undefined) {
                state.config[room._id] = room; // TOMC: REACTIVE
            }
            const roomConfig = state.config[room._id];
            if (roomConfig === undefined)
                return roomConfig;
            if (!helpers.compareObjects(roomConfig, room)) {
                state.config[room._id] = room; // TOMC: REACTIVE
            }
        }
    },
    delete(state, roomid) {
        if (state.config[roomid] !== undefined) {
            delete state.config[roomid]; // TOMC: REACTIVE
        }
        if (state.status[roomid] !== undefined) {
            delete state.status[roomid]; // TOMC: REACTIVE
        }
    },
    // eslint-disable-next-line max-lines-per-function
    updateStatus(state, status) {
        // @ts-ignore
        if (state.status[status.id] === undefined)
            state.status[status.id] = {}; // TOMC: REACTIVE
        const statStatus = state.status[status.id];
        if (statStatus === undefined)
            return;
        for (const [key, value] of Object.entries(status)) {
            // @ts-ignore
            statStatus[key] = value; // TOMC: REACTIVE
        }
        if (Object.values(state.status).length === 1) {
            updateSingleState(state, 'score', Math.round(status.score));
            if (status.timeleft)
                updateSingleState(state, 'timer', Math.round(status.timeleft));
            updateSingleState(state, 'level', status.level);
            updateSingleState(state, 'attempts', status.attempts);
            updateSingleState(state, 'availableButtons', status.available_buttons);
            updateSingleState(state, 'numHidden', status.hidden);
            updateSingleState(state, 'numBlurred', status.blurred);
            updateSingleState(state, 'currentCombination', status.combination);
            updateSingleState(state, 'currentSelection', status.current_selections);
            updateSingleState(state, 'type', status.type);
            updateSingleState(state, 'clockRunning', status.clock_running);
            updateSingleState(state, 'mistakesLeft', status.mistakes_left);
            updateSingleState(state, 'runningTimer', status.running_timer);
            if (status.team !== null)
                updateSingleState(state, 'playingTeam', status.team);
            updateSingleState(state, 'forbiddenColors', status.forbidden_colors);
            updateSingleState(state, 'timeleftButton', status.timeleft_button);
            updateSingleState(state, 'ingameStatus', status.ingame_state);
            updateSingleState(state, 'gameState', status.state);
            updateSingleState(state, 'motion', status.motion);
            updateSingleState(state, 'presence_start_zone', status.presence_start_zone);
            updateSingleState(state, 'presence_end_zone', status.presence_end_zone);
            updateSingleState(state, 'door_open', status.door_open);
            updateSingleState(state, 'smoke', status.smoke);
            updateSingleState(state, 'sketch', status.sketch);
            updateSingleState(state, 'request_picture_timestamp', status.request_picture_timestamp);
            updateSingleState(state, 'currentInput', status.input);
            updateSingleState(state, 'maxLengthInput', status.max_length);
            updateSingleState(state, 'morse_sound', status.morse_sound);
            updateSingleState(state, 'pending', status.pending);
            updateSingleState(state, 'cheating', status.cheating);
        }
    }
};
