import { API } from '@/BeatoutAPI';
import { filesModuleActionContext } from '.';
import { helpers } from '../../helpers';
export default {
    async get(context) {
        const { commit } = filesModuleActionContext(context);
        const response = await API.getRequest().get('files/index.json');
        commit.set(response.data);
    },
    async delete(context, filename) {
        const { commit } = filesModuleActionContext(context);
        await API.getRequest().delete(`files/${filename}`);
        commit.delete(filename);
    },
    async rename(context, { oldFilename, newFilename }) {
        const { commit } = filesModuleActionContext(context);
        await API.getRequest().put(`files/${oldFilename}`, `{"filename": "${newFilename}"}`);
        // TODO needs to be fixed
        // commit.rename({ oldFilename, newFilename })
        commit.delete(oldFilename);
    },
    async subscribe(context) {
        const { dispatch } = filesModuleActionContext(context);
        await helpers.subscribe('files', { repeat: 10000 }, async () => {
            dispatch.get();
        });
    },
    async unsubscribe(_context) {
        await helpers.unsubscribe('files');
    }
};
