import { GameState, GameType } from '@/types/rooms';
const state = {
    config: {},
    status: {},
    // these are for room widgets
    type: GameType.Unknown,
    score: 0,
    timer: 0,
    level: 0,
    clockRunning: false,
    attempts: 0,
    runningTimer: 0,
    forbiddenColors: [],
    gameState: GameState.Stopped,
    ingameStatus: '',
    mistakesLeft: 0,
    timeleftButton: 0,
    // specific for combination room
    availableButtons: [],
    numHidden: 0,
    numBlurred: 0,
    currentCombination: [],
    currentSelection: [],
    combinationCountdown: 0,
    playingTeam: null,
    motion: false,
    // specific for mazeroom
    smoke: false,
    // specific for keypad room
    currentInput: '',
    maxLengthInput: 1,
    morse_sound: false,
    presence_start_zone: true,
    presence_end_zone: false,
    door_open: false,
    sketch: [],
    request_picture_timestamp: 0,
    pending: false,
    cheating: false
};
export default state;
