export var TeamGameType;
(function (TeamGameType) {
    TeamGameType["Unknown"] = "unknown";
    TeamGameType["Beatout"] = "beat out";
    TeamGameType["KraakDeCode"] = "kraak de code";
})(TeamGameType || (TeamGameType = {}));
export var TeamStatus;
(function (TeamStatus) {
    TeamStatus["Waiting"] = "waiting";
    TeamStatus["Active"] = "active";
    TeamStatus["Finished"] = "finished";
    TeamStatus["Archived"] = "archived";
})(TeamStatus || (TeamStatus = {}));
export const getScheduledRoomIdForTeam = (team, timestamp) => {
    var _a;
    // TODO: make generic
    const slot_duration = 20 * 60;
    if (team.start_time === null)
        return null;
    if (timestamp < team.start_time)
        return null;
    const slot = Math.floor((timestamp - team.start_time) / slot_duration);
    if (slot >= team.schedule.length)
        return null;
    return (_a = team.schedule[slot]) !== null && _a !== void 0 ? _a : null;
};
export const setScheduledRoomIdForTeam = (team, timestamp, roomId) => {
    // TODO: make generic
    const slot_duration = 20 * 60;
    if (team.start_time === null) {
        team.start_time = timestamp;
    }
    if (timestamp < team.start_time)
        return;
    const slot = Math.floor((timestamp - team.start_time) / slot_duration);
    while (slot > team.schedule.length) {
        team.schedule.push(null);
    }
    team.schedule[slot] = roomId;
};
const scorefactor = {
    birdsroom: 0.75,
    lasermaze: 3,
    phoneroom: 5,
    coderoom: 3,
    searchroom: 3.5,
    reactionroom: 1.25,
    arcaderoom: 4,
    spiderroom: 5,
    jungleroom: 0.75,
    repeatroom: 3
};
export const getScoresForTeam = (team) => {
    const scores = {};
    team.games.forEach(game => {
        var _a, _b;
        const room = game.room;
        const factor = (_a = scorefactor[room]) !== null && _a !== void 0 ? _a : 1;
        const score = (game.level + 1) * factor;
        if (score > 0 && ((_b = scores[room]) !== null && _b !== void 0 ? _b : 0 < score)) {
            scores[room] = Math.round(score);
        }
    });
    return scores;
};
