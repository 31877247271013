import { RPiStatus } from './rpistatus';
import { RPiConfig } from './rpiconfig';
import { SensorAction } from "./sensoraction";
export { RPiStatus, RPiConfig };
export var SensorType;
(function (SensorType) {
    SensorType["Unknown"] = "unknown";
    SensorType["Button"] = "button";
    SensorType["Led"] = "led";
    SensorType["TestLed"] = "testled";
    SensorType["Keyboard"] = "keyboard";
    SensorType["Webpage"] = "webpage";
    SensorType["LightSensor"] = "lightsensor";
    SensorType["TestButton"] = "testbutton";
    SensorType["RFIDReader"] = "rfidreader";
    SensorType["TestRFIDReader"] = "testrfidreader";
    SensorType["LedStrip"] = "ledstrip";
    SensorType["SpiderWeb"] = "spiderweb";
})(SensorType || (SensorType = {}));
export class Sensor {
    constructor(data = undefined) {
        this.id = '';
        this.type = SensorType.Unknown;
        this.data = {};
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('Sensor load: data is not an object');
        this.id = typeof data.id === 'string' ? data.id : '';
        this.type = (Object.values(SensorType).includes(data.type)) ? data.type : SensorType.Unknown;
        this.data = typeof data.data === 'object' ? data.data : {};
    }
}
export class IfThisThenThat {
    constructor(data = undefined) {
        this.id = '';
        this.if = new SensorAction();
        this.then = new SensorAction();
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('IfThisThenThat load: data is not an object');
        this.id = typeof data.id === 'string' ? data.id : '';
        this.if = data.if !== undefined ? new SensorAction(data.if) : new SensorAction();
        this.then = data.then !== undefined ? new SensorAction(data.then) : new SensorAction();
    }
}
export var HealthStatus;
(function (HealthStatus) {
    HealthStatus["HEALTHY"] = "Healthy";
    HealthStatus["WARNING"] = "Warning";
    HealthStatus["UNHEALTHY"] = "Unhealthy";
    HealthStatus["UNKNOWN"] = "Unknown";
})(HealthStatus || (HealthStatus = {}));
export class ServerStatus {
    constructor(data = undefined) {
        this.cpu = 0;
        this.mem = 0;
        this.diskspace = 0;
        this.ip = 'unknown';
        this.vpn = 'unknown';
        this.uptime = 0;
        this.status = HealthStatus.UNKNOWN;
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('ServerStatus load: data is not an object');
        this.cpu = typeof data.cpu === 'number' ? data.cpu : 0;
        this.mem = typeof data.mem === 'number' ? data.mem : 0;
        this.diskspace = typeof data.diskspace === 'number' ? data.diskspace : 0;
        this.uptime = typeof data.uptime === 'number' ? data.uptime : 0;
        this.ip = typeof data.ip === 'string' ? data.ip : 'unknown';
        this.vpn = typeof data.vpn === 'string' ? data.vpn : 'unknown';
        this.status = Object.values(HealthStatus).includes(data.status) ? data.status : HealthStatus.UNKNOWN;
    }
}
export class ServiceStatus {
    constructor(data = undefined) {
        this.name = 'unknown';
        this.running = false;
        this.status = HealthStatus.UNKNOWN;
        this.cpu = 0;
        this.mem = 0;
        this.versions = { installed_version: null, available_versions: [] };
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('ServiceStatus load: data is not an object');
        this.cpu = typeof data.cpu === 'number' ? data.cpu : 0;
        this.mem = typeof data.mem === 'number' ? data.mem : 0;
        this.running = typeof data.running === 'boolean' ? data.running : false;
        this.name = typeof data.name === 'string' ? data.name : 'unknown';
        this.status = Object.values(HealthStatus).includes(data.status) ? data.status : HealthStatus.UNKNOWN;
        this.versions = typeof data.versions === 'object' ? data.versions
            : { installed_version: null, available_versions: [] };
    }
}
const updateService = (status, serviceKey, data) => {
    const localService = status.services[serviceKey];
    if (localService === undefined) {
        console.warn('Trying to update local service, but does not exists');
        return;
    }
    localService.load(data);
};
const updateRPi = (status, rpiKey, data) => {
    const localRPi = status.rpi[rpiKey];
    if (localRPi === undefined) {
        console.warn('Trying to update local service, but does not exists');
        return;
    }
    localRPi.load(data);
};
export class HardwareStatusMap {
    constructor(data = undefined) {
        this.rpi = {};
        this.server = new ServerStatus();
        this.services = {};
        this.status = HealthStatus.UNKNOWN;
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('HardwareStatusMapt: data is not a dictionary');
        this.status = Object.values(HealthStatus).includes(data.status) ? data.status : HealthStatus.UNKNOWN;
        if (typeof data.rpi === 'object') {
            Object.keys(data.rpi).filter(key => this.rpi[key] === undefined).forEach(key => {
                this.rpi[key] = new RPiStatus(data.rpi[key]); // TOMC: REACTIVE
            });
            Object.keys(this.rpi).filter(key => data.rpi[key] === undefined).forEach(key => {
                delete this.rpi[key]; // TOMC: REACTIVE
            });
            Object.entries(data.rpi).forEach(([key, value]) => updateRPi(this, key, value));
        }
        else {
            this.rpi = {};
        }
        if (typeof data.server !== undefined)
            this.server.load(data.server);
        if (typeof data.services === 'object') {
            Object.keys(data.services).filter(key => this.services[key] === undefined).forEach(key => {
                this.services[key] = new ServiceStatus(data.services[key]); // TOMC: REACTIVE
            });
            Object.keys(this.services).filter(key => data.services[key] === undefined).forEach(key => {
                delete this.services[key]; // TOMC: REACTIVE
            });
            Object.entries(data.services).forEach(([key, value]) => updateService(this, key, value));
        }
        else {
            this.services = {};
        }
    }
}
