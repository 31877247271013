import { API } from '@/BeatoutAPI';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { helpers } from '../../helpers';
import { roomModuleActionContext } from '.';
import store from '@/store';
const sleep = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};
export const sleepUntilTrue = async (testFunction, maxTimeout) => {
    const start = (new Date()).getTime();
    while (!testFunction() && start + maxTimeout > (new Date()).getTime()) {
        await sleep(100);
    }
    return testFunction();
};
const actionsSubscriptions = [];
const subscriptions = {
    roomstatus: {}
};
const isMobileDevice = window.orientation !== undefined || (navigator.userAgent.includes('IEMobile'));
/*eslint max-lines-per-function: ["error", 100]*/
const createWebSocket = async (roomid, sub) => {
    if (sub.ws)
        sub.ws.close();
    if (roomid === 'debug') {
        sub.ws = new ReconnectingWebSocket('ws://127.0.0.1:10000/room/socket');
        /*} else if (roomid === 'arcade') {
          sub.ws = new ReconnectingWebSocket('ws://127.0.0.1:4014/room/socket')
        /*} else if (roomid === 'coderoom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4006/room/socket') */
        /*} else if (roomid === 'birdsroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4013/room/socket')*/
        /* } else if (roomid === 'dressup') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4010/room/socket') */
        /* } else if (roomid === 'phoneroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4009/room/socket') */
        /* } else if (roomid === 'lasermaze') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4005/room/socket') */
        /* } else if (roomid === 'searchroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4011/room/socket') */
        /*} else if (roomid === 'reactionroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4008/room/socket') */
        /*} else if (roomid === 'spiderroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4020/room/socket')
        } else if (roomid === 'repeatroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4022/room/socket')*/
        /* } else if (roomid === 'jungleroom') {
          sub.ws = new ReconnectingWebSocket('ws://localhost:4021/room/socket') */
    }
    else {
        const url = `wss://${store.getters.location.currentLocation.apiurl}/api/room/${roomid}/socket`;
        sub.ws = new ReconnectingWebSocket(url);
    }
    sub.ws.onmessage = status => {
        const message = JSON.parse(status.data);
        if (message.action === 'heartbeat') {
            if (sub.heathbeatChecker)
                clearTimeout(sub.heathbeatChecker);
            sub.heathbeatChecker = window.setTimeout(() => {
                createWebSocket(roomid, sub);
            }, 45000);
        }
        else if (message.event_type !== undefined) {
            try {
                actionsSubscriptions.forEach(action => { action(message.event_type, message.data); });
            }
            catch (error) {
                console.error(`Error handling actions for rooms ${error}`);
            }
        }
        else {
            store.commit.rooms.updateStatus({ id: roomid, ...message });
        }
    };
    sub.ws.addEventListener('error', error => { console.error('Websocket closed', error); });
    sub.ws.onclose = event => { console.error('Websocket closed', event); };
    await sleepUntilTrue(() => store.state.rooms.status[roomid] !== undefined, 10000);
};
export default {
    async get(context, roomid) {
        const { commit } = roomModuleActionContext(context);
        if (roomid === undefined) {
            const response = await API.getRequest().get('rooms/all');
            commit.setConfigs(response.data);
        }
        else {
            const response = await API.getRequest().get('rooms/' + roomid);
            commit.setConfig(response.data);
        }
    },
    async update(context, roomconfig) {
        const { commit } = roomModuleActionContext(context);
        await API.getRequest().put('rooms/' + roomconfig._id, JSON.stringify(roomconfig));
        commit.setConfig(roomconfig);
    },
    async add(context, roomconfig) {
        const { commit } = roomModuleActionContext(context);
        await API.getRequest().post('rooms/new', JSON.stringify(roomconfig));
        commit.setConfig({ _id: 'new', ...roomconfig });
    },
    async reboot(_context, roomid) {
        await API.getRequest().put('rooms/' + roomid, JSON.stringify({ _id: roomid }));
    },
    async delete(context, roomid) {
        const { commit } = roomModuleActionContext(context);
        await API.getRequest().delete('rooms/' + roomid);
        commit.delete(roomid);
    },
    async updateStatus(_context, parameters) {
        var _a;
        const ws = (_a = subscriptions.roomstatus[parameters.roomid]) === null || _a === void 0 ? void 0 : _a.ws;
        if (ws !== undefined) {
            ws.send(JSON.stringify(parameters.data));
        }
        else {
            await API.getRequest().put(`room/${parameters.roomid}/status`, JSON.stringify(parameters.data));
        }
    },
    async setLed(context, { roomid, led, value }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'led', id: led, status: value };
        await dispatch.updateStatus({ roomid, data });
    },
    async setCustomAction(context, payload) {
        const { dispatch } = roomModuleActionContext(context);
        await dispatch.updateStatus({ roomid: payload.roomid, data: { action: payload.action, params: payload.params } });
    },
    async setLedStrip(context, { roomid, ledstrip, pattern }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'ledstrip', id: ledstrip, status: pattern };
        await dispatch.updateStatus({ roomid, data });
    },
    async setSpiderWeb(context, { roomid, spiderweb, value }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'spiderweb', id: spiderweb, status: value };
        await dispatch.updateStatus({ roomid, data });
    },
    async setButton(context, { roomid, button, value }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'button', id: button, status: value };
        await dispatch.updateStatus({ roomid, data });
    },
    async setKeyboard(context, { roomid, keyboard, value }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'keyboard', id: keyboard, status: value };
        await dispatch.updateStatus({ roomid, data });
    },
    async setRFID(context, { roomid, rfid, value }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'rfidreader', id: rfid, status: value };
        await dispatch.updateStatus({ roomid, data });
    },
    async setState(context, { roomid, state }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'state', state: state };
        await dispatch.updateStatus({ roomid, data });
    },
    async setLevel(context, { roomid, level }) {
        const { dispatch } = roomModuleActionContext(context);
        const data = { action: 'level', status: level };
        await dispatch.updateStatus({ roomid, data });
    },
    async subscribeRoomActions(_context, callback) {
        actionsSubscriptions.push(callback);
    },
    async subscribe(context, roomid) {
        const { dispatch } = roomModuleActionContext(context);
        await (roomid === undefined ? helpers.subscribe('rooms', { repeat: 10000 }, async () => {
            dispatch.get(undefined);
        }) : helpers.subscribe('room/' + roomid, { repeat: 10000 }, async () => {
            dispatch.get(roomid);
        }));
    },
    async unsubscribe(_context, roomid) {
        const key = roomid === undefined ? 'rooms' : `room/${roomid}`;
        await helpers.unsubscribe(key);
    },
    async subscribeStatus(context, roomid) {
        var _a;
        const { commit } = roomModuleActionContext(context);
        const url = new URL(window.location.href);
        const method = (_a = url.searchParams.get('method')) !== null && _a !== void 0 ? _a : 'websocket';
        if (subscriptions.roomstatus[roomid] === undefined) {
            subscriptions.roomstatus[roomid] = { count: 0, destroy: undefined, ws: undefined, heathbeatChecker: undefined };
        }
        const roomStatusSubscription = subscriptions.roomstatus[roomid];
        if (roomStatusSubscription === undefined)
            return;
        if (roomStatusSubscription.count === 0) {
            // TODO, calculate type properly
            if (['printer', 'camera'].includes(roomid)) {
                // FIXME
                // commit.updateStatus({ type: GameType(roomid), id: roomid })
            }
            else if (isMobileDevice || method === 'rest') {
                const response = await API.getRequest().get(`room/${roomid}/status`);
                commit.updateStatus({ id: roomid, ...response.data });
                roomStatusSubscription.destroy = window.setInterval(async () => {
                    const response = await API.getRequest().get(`room/${roomid}/status`);
                    commit.updateStatus({ id: roomid, ...response.data });
                }, 1000);
            }
            else {
                if (roomStatusSubscription.ws === undefined) {
                    await createWebSocket(roomid, roomStatusSubscription);
                }
            }
        }
        roomStatusSubscription.count++;
    },
    async unsubscribeStatus(_context, roomid) {
        const roomStatusSubscription = subscriptions.roomstatus[roomid];
        if (roomStatusSubscription === undefined || roomStatusSubscription.count === 0) {
            throw (new Error('You should not unsubscribe if you have not subscribed yet'));
        }
        roomStatusSubscription.count--;
        if (isMobileDevice && roomStatusSubscription.count === 0) {
            const destroyFunction = roomStatusSubscription.destroy;
            if (destroyFunction !== undefined) {
                window.clearInterval(destroyFunction);
                roomStatusSubscription.destroy = undefined;
            }
        }
        else if (roomStatusSubscription.count === 0) {
            const hbdestroy = roomStatusSubscription.heathbeatChecker;
            if (hbdestroy !== undefined)
                clearTimeout(hbdestroy);
            const ws = roomStatusSubscription.ws;
            if (ws !== undefined)
                ws.close();
        }
    }
};
