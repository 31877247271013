import { API } from '@/BeatoutAPI';
import { helpers } from '../../helpers';
import { hardwareModuleActionContext } from '.';
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
    async restartAll(context) {
        const { dispatch } = hardwareModuleActionContext(context);
        const response = await API.getRequest().get('hardware/configs');
        const data = response.data;
        for (const config of data.values()) {
            const id = config._id;
            await dispatch.update({ id });
        }
    },
    async get(context, _piid) {
        // TODO implement piid as parameter
        const { commit } = hardwareModuleActionContext(context);
        let response = await API.getRequest().get('hardware/configs');
        commit.setConfig(response.data);
        response = await API.getRequest().get('hardware/all');
        commit.setState(response.data);
    },
    async vnc(_context, id) {
        await API.getRequest().put(`hardware/vnc/${id}`);
        await sleep(10000);
    },
    async update(context, data) {
        const { commit } = hardwareModuleActionContext(context);
        const id = data.id;
        delete data.id;
        await API.getRequest().put(`hardware/${id}`, JSON.stringify(data));
        if (id === undefined)
            throw new Error('Hardware update action: id was not provided');
        commit.update({ id, config: data });
    },
    async delete(context, rpi) {
        const { commit } = hardwareModuleActionContext(context);
        await API.getRequest().delete(`hardware/${rpi}`);
        commit.delete(rpi);
    },
    async upgrade(_context, id) {
        await API.getRequest().put(`hardware/update/${id}`, '');
    },
    async subscribe(context, piid) {
        const { dispatch } = hardwareModuleActionContext(context);
        await (!piid ? helpers.subscribe('hardware', { repeat: 10000 }, () => {
            return dispatch.get(undefined);
        }) : helpers.subscribe(`hardware/${piid}`, { repeat: 10000 }, () => {
            return dispatch.get(piid);
        }));
    },
    async unsubscribe(_context, piid) {
        const key = piid ? `hardware/${piid}` : 'hardware';
        await helpers.unsubscribe(key);
    },
    async reset(_context, params) {
        try {
            const result = await API.getRequest().post('reset', JSON.stringify(params));
            return result.status === 200;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },
    async softreset(_context, params) {
        try {
            const result = await API.getRequest().post('softreset', JSON.stringify(params));
            return result.status === 200;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    }
};
