import { helpers } from '../../helpers';
export default {
    set(state, files) {
        const fileobject = [];
        files.forEach(file => { fileobject.push({ filename: file }); });
        // @ts-ignore
        helpers.updateStateToObject(state, fileobject, 'filename');
    },
    delete(state, filename) {
        delete state[`media/${filename}`]; // TOMC: REACTIVE
    },
    rename(state, newFilename) {
        state[newFilename] = { filename: newFilename }; // TOMC: REACTIVE
    }
};
