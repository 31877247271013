import { helpers } from '../../helpers';
const mutations = {
    set(state, users) {
        // @ts-ignore
        helpers.updateStateToObject(state, Object.values(users), '_id');
    },
    delete(state, userid) {
        delete state[userid]; // TOMC: REACTIVE
    }
};
export default mutations;
