// @ts-ignore
import { helpers } from '../../helpers';
// import { BeatoutLanguage } from '@/types/translations'
export default {
    set(storestate, { teams, state }) {
        for (const team of teams) {
            if (team.schedule === undefined)
                team.schedule = [];
        }
        // @ts-ignore
        helpers.updateStateToObject(storestate[state], teams, '_id');
    },
    update(state, team) {
        Object.entries(state).forEach(([key, teamstate]) => {
            if (teamstate[team.id]) {
                // This is temporary, until we have a language field in the database
                // if (team.language === undefined) {
                //   team.language = BeatoutLanguage.Dutch
                // }
                teamstate[team.id] = { ...teamstate[team.id], ...team }; // TOMC: REACTIVE
                const currentTeamState = teamstate[team.id];
                if (currentTeamState === undefined) {
                    console.error('store:team:update -> currentTeamState is undefined');
                    return;
                }
                if (currentTeamState.state !== key) {
                    // when we bring someone live, set the start date so the UI starts counting down already
                    if (currentTeamState.state === 'active') {
                        currentTeamState.start_time = (new Date()).getTime() / 1000;
                    }
                    state[currentTeamState.state][team.id] = { ...currentTeamState }; // TOMC: REACTIVE
                    delete teamstate[team.id]; // TOMC: REACTIVE
                }
            }
        });
    },
    setDataReceived(state, status) {
        state.dataReceived[status] = true;
    },
    delete(state, id) {
        Object.values(state).forEach(teamstate => {
            if (teamstate[id]) {
                delete teamstate[id]; // TOMC: REACTIVE
            }
        });
    }
};
