/* eslint-disable sonarjs/no-duplicate-string */
export const EnglishTranslation = {
    levelUp: 'Level up',
    levelUpTagline: 'Good luck on the next level!',
    levelProgress: 'Level Progress',
    pushButton: 'Push a random button to start',
    pushButtonIlluminated: 'Push a illuminated button to start',
    attemptsNeeded: 'attempt(s) needed',
    attemptsLeft: 'attempts left in this level',
    gamePausesNewInstructions: 'At the start of the game, the game pauses. New instructions will appear on the screen.',
    gameStartsInMoment: 'Game starts in',
    red: 'Red',
    blue: 'Blue',
    green: 'Green',
    yellow: 'Yellow',
    reachedLevel: 'Whauw, you did conquer level {level}!',
    introductionTitle: 'Game explanation',
    introductionGameStarts: 'Game starts after the explanation',
    reactionRoomHighLevel: 'Those were lightning fast reactions!<br/>Congratulations!',
    reactionRoomDescrLevel1: 'Game exploration, getting to know the game<br/> Press the button within <b>25 seconds</b>',
    reactionRoomDescrLevel2: 'Press the next button within <b>10 seconds</b>',
    reactionRoomDescrLevel3: 'Press the next button within <b>7 seconds</b>',
    reactionRoomDescrLevel4: 'Press the allow button within <b>7 seconds</b>.' +
        '<br/>Attention: you may <b>NOT</b> press the indicated color on the screen.',
    reactionRoomDescrLevel5: 'Press the allow button within <b>10 seconds</b>.' +
        '<br/>Attention: you may <b>NOT</b> press the indicated color on the screen.' +
        '<br/>This color changes with each press.',
    reactionRoomDescrLevel6: 'Press the allow button within <b>7 seconds</b>.' +
        '<br/>Attention: you may <b>NOT</b> press the indicated color on the screen.' +
        '<br/>This color changes with each press.',
    reactionRoomDescrLevel7: 'Press the allow button within <b>10 seconds</b>.' +
        '<br/>Attention: you may <b>NOT</b> press the colors on the screen.' +
        '<br/>This color changes with each press.',
    reactionRoomDescrLevel8: 'Press the allow button within <b>7 seconds</b>.' +
        '<br/>Attention: <b>written</b> colors on the screen should <b>NOT</b> be pressed.' +
        '<br/>This color changes with each press.',
    reactionRoomDescrLevel9: 'Press the allow button within <b>5 seconds</b>.' +
        '<br/>Attention: <b>written</b> colors on the screen should <b>NOT</b> be pressed.' +
        '<br/>This color changes with each press.',
    reactionRoomDescrLevel10: 'Press the allow button within <b>4 seconds</b>.' +
        '<br/>Attention: <b>written</b> colors on the screen should <b>NOT</b> be pressed.' +
        '<br/>This color changes with every press action.',
    codeRoomChallenge: 'Give the solution of a riddle and rise a level.',
    birdsProgress: 'Team vs Crows',
    birdsThrowBallToStart: 'Toss a ball into a colored hole to start',
    birdsSubtitle: 'Crows fly in. Can you stop these?',
    birdsChallenge: 'Throw balls into the holes until they turn green.',
    codeRoomIncorrect: 'The entered code is wrong',
    codeRoomSuccess: 'Sherlock Holmes stands shyly beside you.',
    laserSubtitle: 'Can you go through the maze without hitting the lasers?',
    laserWait: 'Wait to start',
    laserBadgeToStart: 'Scan to start the next level.',
    laserFailed: 'Failed ...<br>Return and scan to try again.',
    laserReturnToStart: 'Go back to the startzone.<br>We check the game area.',
    laserCalibrate: 'Wait before starting<br>Preparing next level',
    laserCloseDoor: 'Close the door before starting.',
    laserSuccess: 'You are masters in burglary!',
    laserDescription: 'Can you all go through the maze?<br/>With each hit you <b>lose 35 points</b>.',
    phoneTitle: 'Telephone Central',
    phoneSubtitle: 'Connect the persons by pressing the buttons below their photo at the same time',
    phoneCombinationCorrect: 'Combination made!<br/>Next call coming soon.',
    phoneCombinationWrong: '<b>Failed</b><br>Release all buttons and wait for a new combination.',
    phoneCloseDoor: 'Door must remain closed.',
    searchSubtitle: 'Find out who is repairing what and where.',
    searchStartText: 'Find out who is present',
    searchCorrect: 'Combination made!<br/>Next call will come shortly.',
    searchWrong: '<b>Failed</b><br>Release all buttons and wait for a new combination.',
    searchRoomDescrLevelPersons: 'Find <b>ONE GREEN person</b> and press it on the panel.' +
        '<br><br>OR<br><br>' +
        'Find <b>THREE RED people</b> and press the button of the non-colored person.',
    searchRoomDescrLevelPersonsBroken: 'Look for the person AND what he is repairing',
    searchRoomDescrLevelPersonsBrokenTools: 'Look for the person <br/><b>AND</b> what he is repairing <br>' +
        '<b>AND</b> to the tool he uses',
    arcadeRoomHighLevel: 'You notice that you used to play a lot!',
    tetrisLines: 'Make {lines} rows for the next level.',
    tetrisLinesCompleted: 'Already {linesCompleted} lines created.',
    spiderCrawl: 'Crawl <b>one by one</b> through a <b>green hole without touching the borders</b>.<br> \
  <br>Once you start, the hole will turn yellow.',
    spiderAfterCrawl: 'After each pass-through, press buttons in start and endbox.',
    spiderCountPlayers: 'Let\'s have a count on the participants',
    congratsPersonOver: 'Congrats! One more succesful pass-through.',
    returnAllToStart: 'Go <b>all</b> to the starting area',
    allPressButtons: 'Everyone should press a set of buttons',
    pressButtonsAbove: 'Press and hold two buttons <b>above</b> each other in the box.',
    keepHoldingButtons: 'Keep holding both buttons for <b>{time}</b> seconds.',
    buttonsPressed: 'buttons pressed',
    pressThisButton: 'Press this button',
    jungleSubtitle: 'Can you stop the evil jungle colony',
    jungleThrowBallToStart: 'Throw a ball against an active creature to start',
    jungleChallenge: 'Throw balls against the creatures with red eys untill all are de-activated',
    repeatRoomHighLevel: 'Great minds in this team, great performance!',
    repeatSerieWithButtonNext: 'Repeat the sequence with this button attached',
    startSerieWithButtonNext: 'Start the new sequence with this button',
    allRoomsPlayed: 'All planned rooms are conquered!',
    allRoomsPlayedKokiBar: 'Go to the KokiBar to see the scores.',
    nextRoomPlanned: 'Next room is the {nextRoom}.',
    succeeded: 'Congrats!',
    notSucceeded: 'To bad, better luck next time',
    startZone: 'Startzone',
    endZone: 'Endzone',
    ofThe: 'out of',
    persons: 'persons',
    doNotStandOnPlatform: 'Don\'t stand on the platform'
};
