import { API } from '@/BeatoutAPI';
import { helpers } from '../../helpers';
const actions = {
    async add({ dispatch }, user) {
        await API.getRequest().post('users/new', JSON.stringify(user));
        await dispatch('get');
    },
    async get({ commit }) {
        const response = await API.getRequest().get('users/all');
        commit('set', response.data);
    },
    async delete({ commit }, userid) {
        await API.getRequest().delete('users/' + userid);
        commit('delete', userid);
    },
    async update({ dispatch }, user) {
        const id = user._id;
        // @ts-ignore
        delete user._id;
        await API.getRequest().put('users/' + id, JSON.stringify(user));
        await dispatch('get');
    },
    async sendNotification(_context, notification) {
        await API.getRequest().post('push', JSON.stringify(notification));
    },
    async subscribe({ dispatch, commit }) {
        return helpers.subscribe('users', { repeat: 30000, commit }, async () => {
            dispatch('get');
        });
    },
    async unsubscribe({ commit }) {
        return helpers.unsubscribe('users', commit);
    }
};
export default actions;
