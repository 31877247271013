export const locations = {
    dekluis: {
        name: 'De Kluis',
        apiurl: 'dekluis.ddns.net'
    },
    local: {
        name: 'Local',
        apiurl: '192.168.0.12'
    },
    localhost: {
        name: 'Localhost',
        apiurl: 'localhost'
    }
};
