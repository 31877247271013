/* eslint-disable sonarjs/no-duplicate-string */
export const FrenchTranslation = {
    levelUp: 'Passer au niveau supérieur',
    levelUpTagline: 'Bonne chance pour le prochain niveau !',
    levelProgress: 'Progression du niveau',
    pushButton: 'Appuyez sur un bouton au hasard pour commencer',
    pushButtonIlluminated: 'Appuyez sur un bouton allumé pour commencer',
    attemptsNeeded: 'tentative(s) nécessaires',
    attemptsLeft: 'tentatives restantes à ce niveau',
    gamePausesNewInstructions: 'Au début du jeu, le jeu se met en pause. ' +
        'De nouvelles instructions apparaîtront à l\'écran.',
    gameStartsInMoment: 'Le jeu commence dans',
    red: 'Rouge',
    blue: 'Bleu',
    green: 'Vert',
    yellow: 'Jaune',
    reachedLevel: 'Waouh, vous avez atteint le niveau {level} !',
    introductionTitle: 'Explication du jeu',
    introductionGameStarts: 'Le jeu commence après l\'explication',
    reactionRoomHighLevel: 'Ce sont des réactions exelent !<br/>Félicitations !',
    reactionRoomDescrLevel1: 'Exploration du jeu, apprendre à connaître le jeu<br/>' +
        'Appuyez sur le bouton dans un délai de <b>25 secondes</b>',
    reactionRoomDescrLevel2: 'Appuyez sur le bouton suivant dans un délai de <b>10 secondes</b>',
    reactionRoomDescrLevel3: 'Appuyez sur le bouton suivant dans un délai de <b>7 secondes</b>',
    reactionRoomDescrLevel4: 'Appuyez sur le bouton autorisé dans un délai de <b>7 secondes</b>.' +
        '<br/>Attention : vous ne pouvez pas appuyer la couleur indiquée à l\'écran.',
    reactionRoomDescrLevel5: 'Appuyez sur le bouton autorisé dans un délai de <b>10 secondes</b>.' +
        '<br/>Attention : vous ne pouvez pas appuyer la couleur indiquée à l\'écran.' +
        '<br/>Cette couleur change à chaque fois que vous appuyez sur un bouton.',
    reactionRoomDescrLevel6: 'Appuyez sur le bouton autorisé dans un délai de <b>7 secondes</b>.' +
        '<br/>Attention : vous ne pouvez pas appuyer sur la couleur indiquée à l\'écran.' +
        '<br/>Cette couleur change à chaque fois que vous appuyez sur un bouton.',
    reactionRoomDescrLevel7: 'Appuyez sur le bouton autorisé dans un délai de <b>10 secondes</b>.' +
        '<br/>Attention : vous ne pouvez pas appuyer sur les couleurs à l\'écran.' +
        '<br/>Cette couleur change à chaque fois que vous appuyez sur un bouton.',
    reactionRoomDescrLevel8: 'Appuyez sur le bouton autorisé dans un délai de <b>7 secondes</b>.' +
        '<br/>Attention : les couleurs <b>écrites</b> à l\'écran ne peuvent <b>pas</b> être appuyées.' +
        '<br/>Cette couleur change à chaque fois que vous appuyez sur un bouton.',
    reactionRoomDescrLevel9: 'Appuyez sur le bouton autorisé dans les <b>5 secondes</b>.' +
        '<br/>Attention: Un bouton de couleur <b>écrites</b> à l\'écran ne peut <b>PAS</b> être appuyé.<br/>' +
        'Cette couleur change à chaque fois que vous appuyez sur un bouton.',
    reactionRoomDescrLevel10: 'Appuyez sur le bouton permettant d\'autoriser dans <b>4 secondes</b>.<br/>' +
        'Attention: les couleurs <b>écrites</b> à l\'écran ne peuvent <b>PAS</b> être appuyées.<br/>' +
        'Cette couleur change à chaque que vous appuyez sur un bouton.',
    codeRoomChallenge: 'Tapez la solution d\'un code et montez d\'un niveau.',
    birdsProgress: 'Équipe contre les corbeaux',
    birdsThrowBallToStart: 'Lancez une balle dans un trou jaune pour commencer.',
    birdsSubtitle: 'Les corbeaux volent. Pouvez-vous les arrêter ?',
    birdsChallenge: 'Lancez des balles dans les trous jusqu\'à ce qu\'ils deviennent verts.',
    codeRoomIncorrect: 'Le code entré est incorrect',
    codeRoomSuccess: 'Sherlock Holmes se tient timidement à côté de vous.',
    laserSubtitle: 'Pouvez-vous traverser le labyrinthe sans toucher les lasers ?',
    laserWait: 'Attendez pour commencer',
    laserBadgeToStart: 'Scannez pour commencer le prochain niveau.',
    laserFailed: 'Échec ...<br>Retournez et scannez pour réessayer.',
    laserReturnToStart: 'Retournez à la zone de départ.<br>Nous vérifions la zone de jeu.',
    laserCalibrate: 'Attendre avant de commencer<br>Préparation du niveau suivant',
    laserCloseDoor: 'Fermez la porte avant de commencer.',
    laserSuccess: 'Vous êtes des maîtres du cambriolage !',
    laserDescription: 'Pouvez-vous tous traverser le labyrinthe?<br/>À chaque touche, vous <b>perdez 35 points</b>.',
    phoneTitle: 'Centrale téléphonique',
    phoneSubtitle: 'Connectez les personnes en appuyant les boutons sous leur photo en même temps.',
    phoneCombinationCorrect: 'Combinaison réussie !<br/>Le prochain appel arrive bientôt.',
    phoneCombinationWrong: '<b>Échec</b><br>Relâchez tous les boutons et attendez une nouvelle combinaison.',
    phoneCloseDoor: 'La porte doit rester fermée.',
    searchSubtitle: 'Découvrez qui répare quoi et où.',
    searchStartText: 'Découvrez qui est présent',
    searchCorrect: 'Combinaison faite!<br/>Le prochain appel aura lieu sous peu.',
    searchWrong: '<b>Échec!</b> Lâchez tous les boutons et attendez une nouvelle combinaison.',
    searchRoomDescrLevelPersons: 'Trouvez <b>Une personne VERTE</b> et appuyez dessus sur le panneau.' +
        '<br><br>OU<br><br>' +
        'Trouvez <b>TROIS personnes ROUGES</b> et appuyez sur le bouton de la personne non colorée.',
    searchRoomDescrLevelPersonsBroken: 'Recherchez la personne ET ce qu\'elle récupère',
    searchRoomDescrLevelPersonsBrokenTools: 'Recherchez la personne <br/><b>ET</b> ce qu\'elle récupère <br>' +
        '<b>ET</b> à la commodité avec laquelle il répare ceci',
    arcadeRoomHighLevel: 'Il est clair que vous avez beaucoup joué auparavant !',
    tetrisLines: 'Faites {lines} rangées pour le prochain niveau.',
    tetrisLinesCompleted: '{linesCompleted} rangées déjà créées.',
    spiderCrawl: 'Rampez <b>un par un</b> à travers un <b>trou vert sans toucher les bordures</b>.<br> \
  <br>Une fois que vous aurez commencé, l’ouverture utilisée devient jaune.',
    spiderAfterCrawl: 'Une fois que quelqu’un est passé à travers une ouverture appuyez tous sur les boutons dans la \
  zone où vous vous trouvez',
    spiderCountPlayers: 'Compteons les participants',
    congratsPersonOver: 'Bravo! Encore un passage réussi.',
    returnAllToStart: 'Allez <b>tous</b> dans la zone de départ',
    allPressButtons: 'Chacun appuie sur un ensemble de boutons',
    pressButtonsAbove: 'Appuyez et maintenez enfoncés deux boutons <b>au-dessus</b> l\'un de l\'autre dans la boîte.',
    keepHoldingButtons: 'Appuyez tous sur les 2 boutons l’un sur l’autre et maintenez-les enfoncés pendant \
  <b>{time}</b>secondes',
    buttonsPressed: 'boutons enfoncés',
    pressThisButton: 'Appuyez sur ceci',
    jungleSubtitle: 'Pouvez-vous arrêter la colonie de jungle hostile?',
    jungleThrowBallToStart: 'Lancez une balle contre une créature active pour commencer',
    jungleChallenge: 'Lancez des balles contre les créatures aux yeux rouges jusqu\'à ce qu\'elles soient toutes' +
        ' désactivées',
    repeatRoomHighLevel: 'De grands esprits dans cette équipe, bravo!',
    repeatSerieWithButtonNext: 'Répétez la série avec ce bouton',
    startSerieWithButtonNext: 'Démarrez la nouveau séquence avec ce bouton',
    allRoomsPlayed: 'Tous les jeux prévus sont conquis !',
    allRoomsPlayedKokiBar: 'Allez au KokiBar pour voir les scores.',
    nextRoomPlanned: 'La chambre suivante est {nextRoom}.',
    succeeded: 'Bravo!',
    notSucceeded: 'Tant pis, meilleure chance la prochaine fois',
    startZone: 'Zone de départ',
    endZone: 'Zone d\'en-but',
    ofThe: 'de la',
    persons: 'persons',
    doNotStandOnPlatform: 'ne reste pas sur la plate-forme'
};
