// @ts-ignore
// import WebCam from 'vue-web-cam'
// Vue.use(WebCam)
// Vue.use(BootstrapVue)
// Vue.use(ModalPlugin)
// @ts-ignore
// Vue.use('vue-chartjs')
// TOMC: Check other plugins
// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import './registerServiceWorker'
// import Toast, { POSITION } from 'vue-toastification'
import "bootstrap";
import { config } from '@vue/test-utils';
config.global.config.compilerOptions = {
    isCustomElement: (tag) => tag !== 'center'
};
