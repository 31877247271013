import axios from 'axios';
import md5 from 'md5';
// @ts-ignore
import Cookies from 'js-cookie';
export class BeatOutAPI {
    constructor(apiurl) {
        this.apiurl = '';
        this.changeApiUrl(apiurl);
        this.auth = null;
        this.username = null;
        this.rights = null;
    }
    changeApiUrl(apiurl) {
        const protocol = ['localhost', '127.0.0.1'].includes(apiurl) ? 'http' : 'https';
        this.apiurl = protocol + '://' + apiurl + '/api/';
    }
    loadFromCookies() {
        var _a, _b, _c;
        try {
            this.username = (_a = Cookies.get('username')) !== null && _a !== void 0 ? _a : null;
            this.auth = (_b = Cookies.get('auth')) !== null && _b !== void 0 ? _b : null;
            this.rights = (_c = Cookies.get('rights')) !== null && _c !== void 0 ? _c : null;
        }
        catch (error) {
            console.log(`Could not load cookies ${error}`);
        }
    }
    isLoggedIn() {
        return this.auth !== null;
    }
    async login(username, password) {
        const auth = this.makeBaseAuth(username, password);
        const request = axios.create({
            baseURL: this.apiurl + 'user',
            timeout: 5000,
            headers: {
                Authorization: auth
            }
        });
        const response = await request.get('');
        if (response.status === 200) {
            Cookies.set('username', username);
            Cookies.set('auth', auth);
            Cookies.set('rights', response.data.rights);
            this.loadFromCookies();
        }
    }
    logout() {
        Cookies.remove('username');
        Cookies.remove('auth');
        Cookies.remove('rights');
        this.loadFromCookies();
    }
    makeBaseAuth(user, password) {
        const tok = user + ':' + md5(password);
        const hash = btoa(tok);
        return 'Basic ' + hash;
    }
    getAuthHeader() {
        this.loadFromCookies();
        return this.auth;
    }
    getRequest(url) {
        var _a;
        return axios.create({
            baseURL: url !== null && url !== void 0 ? url : this.apiurl,
            timeout: 10000,
            headers: {
                Authorization: (_a = this.getAuthHeader()) !== null && _a !== void 0 ? _a : ''
            }
        });
    }
    async getBrains() {
        const response = await this.getRequest().get('cv/brains/all');
        return response.data;
    }
    async getBrain(brainid) {
        const response = await this.getRequest().get(`cv/brains/${brainid}`);
        // todo constructor
        return response.data;
    }
}
export const API = new BeatOutAPI('dekluis.ddns.net');
