import { API } from '@/BeatoutAPI';
import { authModuleActionContext } from '.';
const actions = {
    async loadFromCookies(context) {
        const { commit } = authModuleActionContext(context);
        await API.loadFromCookies();
        commit.auth(undefined);
    },
    async login(context, credentials) {
        const { commit } = authModuleActionContext(context);
        await API.login(credentials.username, credentials.password);
        commit.auth(undefined);
    },
    async logout(context) {
        const { commit } = authModuleActionContext(context);
        await API.logout();
        commit.update(null);
    },
    async get(context) {
        const { commit } = authModuleActionContext(context);
        const response = await API.getRequest().get('user');
        commit.update(response.data);
    },
    async update(context, user) {
        const { commit } = authModuleActionContext(context);
        await API.getRequest().put(`users/${user._id}`, JSON.stringify(user));
        commit.update(user);
    },
    async setNotificationSubscription(_context, subscription) {
        await API.getRequest().put('user', JSON.stringify({ notification: subscription }));
    }
};
export default actions;
