const state = {
    waiting: {},
    active: {},
    finished: {},
    archived: {},
    dataReceived: {
        waiting: false,
        active: false,
        finished: false,
        archived: false
    }
};
export default state;
