import { API } from '@/BeatoutAPI';
import { helpers } from '../../helpers';
import { TeamStatus } from '@/types/teams';
import { teamsModuleActionContext } from '.';
export default {
    async update(context, team) {
        const { dispatch } = teamsModuleActionContext(context);
        await API.getRequest().put(`teams/${team.id}`, JSON.stringify(team));
        await dispatch.get(team.id);
    },
    async delete(context, id) {
        const { commit } = teamsModuleActionContext(context);
        await API.getRequest().delete(`teams/${id}`);
        commit.delete(id);
    },
    async add(context, team) {
        const { dispatch } = teamsModuleActionContext(context);
        await API.getRequest().post('teams/new', JSON.stringify(team));
        dispatch.getTeams(TeamStatus.Waiting);
    },
    async getTeamFromPin(context, pin) {
        const { commit } = teamsModuleActionContext(context);
        const response = await API.getRequest().get(`teampin/${pin}`);
        response.data.id = response.data._id;
        const team = response.data;
        commit.set({ state: 'active', teams: [team] });
    },
    async getTeams(context, state) {
        const { commit, dispatch } = teamsModuleActionContext(context);
        if (state) {
            const response = await API.getRequest().get(`teams/${state}`);
            commit.set({ state, teams: response.data });
            commit.setDataReceived(state);
        }
        else {
            const states = Object.values(TeamStatus);
            const allProm = [];
            states.forEach(teamState => {
                allProm.push(dispatch.getTeams(teamState));
            });
            await Promise.all(allProm);
        }
    },
    async get(context, teamid) {
        const { commit } = teamsModuleActionContext(context);
        const response = await API.getRequest().get(`teams/${teamid}`);
        commit.update(response.data);
    },
    async subscribe(context, state) {
        const { dispatch } = teamsModuleActionContext(context);
        if (!state) {
            const states = Object.values(TeamStatus);
            const teamsubscriptions = [];
            states.forEach(teamState => {
                teamsubscriptions.push(dispatch.subscribe(teamState));
            });
            await Promise.all(teamsubscriptions);
        }
        else {
            await helpers.subscribe(`teams-${state}`, { repeat: 10000 }, async () => {
                dispatch.getTeams(state);
            });
        }
    },
    async unsubscribe(context, state) {
        const { dispatch } = teamsModuleActionContext(context);
        if (!state) {
            const states = Object.values(TeamStatus);
            const teamunsubscriptions = [];
            states.forEach(teamState => {
                teamunsubscriptions.push(dispatch.unsubscribe(teamState));
            });
            await Promise.all(teamunsubscriptions);
        }
        else {
            await helpers.unsubscribe(`teams-${state}`);
        }
    },
    async resetRoomLevel(context, data) {
        const { dispatch, commit } = teamsModuleActionContext(context);
        const response = await API.getRequest().get(`teams/${data.teamId}`);
        const team = response.data;
        team.games.push({ room: data.roomId, reset_level: true });
        if (data.level) {
            team.games.push({ room: data.roomId, level: data.level });
        }
        await API.getRequest().put('teams/' + team._id, JSON.stringify(team));
        await dispatch.get(team._id);
        await commit.update(team);
    }
};
