import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { moduleActionContext } from '@/store';
const softwareModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default softwareModule;
export const softwareModuleActionContext = (context) => moduleActionContext(context, softwareModule);
