import { RPiConfig } from '@/types/hardware';
export default {
    setState(state, hardware) {
        state.status.load(hardware);
    },
    update(state, hardware) {
        // @ts-ignore
        state.config[hardware.id] = hardware.config; // TOMC: REACTIVE
    },
    delete(state, id) {
        delete state.status.rpi[id]; // TOMC: REACTIVE
        delete state.config[id]; // TOMC: REACTIVE
    },
    setConfig(state, configs) {
        configs.forEach(config => {
            var _a;
            const key = config._id;
            if (state.config[key] !== undefined) {
                (_a = state.config[key]) === null || _a === void 0 ? void 0 : _a.load(config);
            }
            else {
                state.config[key] = new RPiConfig(config); // TOMC: REACTIVE
            }
        });
        Object.keys(state.config).filter(key => configs.find(config => config._id === key) === undefined).forEach(key => {
            delete state.config[key]; // TOMC: REACTIVE
        });
    }
};
