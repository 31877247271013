import { API } from '@/BeatoutAPI';
const mutations = {
    auth(state) {
        // TOMC: REACTIVE
        state.username = API.username;
        state.auth = API.auth;
        state.rights = API.rights;
        state.loggedin = API.auth !== null;
    },
    update(state, user) {
        state.user = user; // TOMC: REACTIVE
    }
};
export default mutations;
