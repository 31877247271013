import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { moduleActionContext } from '@/store';
const locationModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default locationModule;
export const locationModuleActionContext = (context) => moduleActionContext(context, locationModule);
