/* global NDEFReader */
import { API } from '@/BeatoutAPI';
import { helpers } from '../helpers';
import store from '..';
import { moduleActionContext } from '@/store';
let reader = null;
const rfidReaderScanned = ({ message, serialNumber }) => {
    const sn = serialNumber.replace(':', '').replace(':', '').replace(':', '').replace(':', '');
    console.log(`> Serial Number: ${serialNumber}`);
    const code = '' + Number.parseInt(sn, 16);
    console.log(`> Records: (${message.records.length})`);
    store.commit.rfid.set({ id: 'counterrfid', rfid: { id: code, rfid: code, text: '' } });
    console.log('Store', store.state.rfid);
};
const state = {
    RFID: {},
    specialRFID: {}
};
const getters = {};
const mutations = {
    set(rfidstate, data) {
        if (data.id !== undefined) {
            rfidstate.RFID[data.id] = { ...data.rfid, id: data.rfid.rfid }; // TOMC: REACTIVE
        }
    },
    setSpecial(rfidstate, data) {
        if (data.id !== undefined) {
            rfidstate.specialRFID[data.id] = data.rfid; // TOMC: REACTIVE
        }
        console.log(rfidstate.specialRFID, data);
    },
    setSpecials(rfidstate, data) {
        rfidstate.specialRFID = {};
        for (const rfid of data) {
            rfidstate.specialRFID[rfid.rfid] = rfid; // TOMC: REACTIVE
        }
    }
};
const actions = {
    async get(context, rfidname) {
        const { commit } = rfidModuleActionContext(context);
        if (rfidname !== undefined) {
            const response = await API.getRequest().get('rfid/' + rfidname);
            commit.set({ id: rfidname, rfid: response.data });
        }
        else {
            const response = await API.getRequest().get('rfid/list');
            commit.setSpecials(response.data);
        }
    },
    async subscribe(context, rfidname) {
        const { dispatch } = rfidModuleActionContext(context);
        if (rfidname !== undefined) {
            await helpers.subscribe('rfid/' + rfidname, { repeat: 1000 }, async () => {
                dispatch.get(rfidname);
            });
            if (reader === null) {
                try {
                    // @ts-ignore
                    reader = new NDEFReader();
                    reader.addEventListener('reading', rfidReaderScanned);
                    await reader.scan();
                }
                catch (error) {
                    console.warn('Could not connect to local NFC reader', error);
                }
            }
        }
        else {
            await helpers.subscribe('rfids', { repeat: 10000 }, async () => {
                dispatch.get(undefined);
            });
        }
    },
    async unsubscribe(_context, rfidname) {
        if (rfidname !== undefined) {
            await helpers.unsubscribe('rfid/' + rfidname);
            if (reader !== null) {
                reader.removeEventListener('reading', rfidReaderScanned);
                reader = null;
            }
        }
        else {
            await helpers.unsubscribe('rfids');
        }
    },
    async add(context, rfid) {
        const { dispatch } = rfidModuleActionContext(context);
        await API.getRequest().post(`rfid/new`, JSON.stringify(rfid));
        dispatch.get(undefined);
    },
    async update(context, rfid) {
        const { dispatch } = rfidModuleActionContext(context);
        await API.getRequest().put(`rfid/${rfid.rfid}`, JSON.stringify(rfid));
        dispatch.get(undefined);
    },
    async delete(context, rfidID) {
        const { dispatch } = rfidModuleActionContext(context);
        await API.getRequest().delete(`rfid/${rfidID}`);
        dispatch.get(undefined);
    }
};
const rfidModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default rfidModule;
export const rfidModuleActionContext = (context) => moduleActionContext(context, rfidModule);
