export default {
    namespaced: true,
    state: {
        now: 0
    },
    getters: {},
    actions: {},
    mutations: {
        update(state) {
            const d = new Date();
            state.now = Math.floor(d.getTime() / 1000); // TOMC: REACTIVE
        }
    }
};
