import '@/setupVueEnv';
import '@/sass/main.scss';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Beat Out',
    data: () => ({
        pageLoaded: true
    }),
    created() {
        this.$store.direct.dispatch.location.loadLocation();
    },
    mounted() {
        window.addEventListener('load', () => {
            this.pageLoaded = true;
        });
    }
});
