import { BeatoutLanguage, translations } from "@/types/translations";
export default {
    roomName: (state) => (roomId) => {
        var _a;
        return (_a = state.config[roomId]) === null || _a === void 0 ? void 0 : _a.name;
    },
    translation: (state) => (translationKey, params) => {
        var _a, _b;
        const language = (_b = (_a = state.playingTeam) === null || _a === void 0 ? void 0 : _a.language) !== null && _b !== void 0 ? _b : BeatoutLanguage.Dutch;
        let text = translations[language][translationKey];
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                text = text.replace(`{${key}}`, value);
            });
        }
        return text;
    }
};
