/* eslint-disable complexity */
import { HealthStatus, RPiConfig, Sensor } from ".";
export class RPiStatus {
    constructor(data = undefined) {
        this.config = new RPiConfig();
        this.id = '';
        this.cpu = 0;
        this.diskspace = 0;
        this.ip = 'unknown';
        this.mem = 0;
        this.name = '';
        this.roundtrip = 0;
        this.sensors = [];
        this.status = HealthStatus.UNKNOWN;
        this.time = 0;
        this.uptime = 0;
        this.version = 'unknown';
        this.vpn = 'unknown';
        this.voltage = 'unknown';
        this.temp = 0;
        if (data !== undefined)
            this.load(data);
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    load(data) {
        if (typeof data !== 'object')
            throw new Error('RPiStatus::load: data is not an object');
        this.config = typeof data.config === 'object' ? new RPiConfig(data.config) : new RPiConfig();
        this.id = typeof data.id === 'string' ? data.id : '';
        this.cpu = typeof data.cpu === 'number' ? data.cpu : 0;
        this.diskspace = typeof data.diskspace === 'number' ? data.diskspace : 0;
        this.ip = typeof data.ip === 'string' ? data.ip : 'unknown';
        this.mem = typeof data.mem === 'number' ? data.mem : 0;
        this.name = typeof data.name === 'string' ? data.name : '';
        this.roundtrip = typeof data.roundtrip === 'number' ? data.roundtrip : 0;
        this.sensors = [];
        if (Array.isArray(data.sensors)) {
            data.sensors.forEach((sensor) => {
                this.sensors.push(new Sensor(sensor));
            });
        }
        this.status = Object.values(HealthStatus).includes(data.status) ? data.status : HealthStatus.UNKNOWN;
        this.time = typeof data.time === 'number' ? data.time : 0;
        this.uptime = typeof data.uptime === 'number' ? data.uptime : 0;
        this.version = typeof data.version === 'string' ? data.version : 'unknown';
        this.vpn = typeof data.vpn === 'string' ? data.vpn : 'unknown';
        this.voltage = typeof data.voltage === 'string' ? data.voltage : 'unknown';
        this.temp = typeof data.temp === 'number' ? data.temp : 0;
    }
}
