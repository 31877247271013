import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { moduleActionContext } from '@/store';
const hardwareModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default hardwareModule;
export const hardwareModuleActionContext = (context) => moduleActionContext(context, hardwareModule);
