export class DetectedObject {
    constructor(data = undefined) {
        this.objectid = '';
        this.probability = 0;
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.brainid = '';
        this.color = '';
        if (data === undefined)
            return;
        if (typeof data.objectid === 'string')
            this.objectid = data.objectid;
        if (typeof data.x === 'number')
            this.x = data.x;
        if (typeof data.y === 'number')
            this.y = data.y;
        if (typeof data.width === 'number')
            this.width = data.width;
        if (typeof data.height === 'number')
            this.height = data.height;
        if (typeof data.probability === 'number')
            this.probability = data.probability;
        if (typeof data.brain_id === 'string')
            this.brainid = data.brain_id;
        if (typeof data.color === 'string')
            this.color = data.color;
    }
}
