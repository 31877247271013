import { API } from '@/BeatoutAPI';
import { moduleActionContext } from '@/store';
const state = {
    schedules: {}
};
const getters = {};
const mutations = {};
const actions = {
    async getSchedule(_context, options) {
        const response = await API.getRequest().post(`scheduler`, JSON.stringify(options));
        return response.data;
    }
};
const scheduleModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default scheduleModule;
export const scheduleModuleActionContext = (context) => moduleActionContext(context, scheduleModule);
