import store from '@/store';
const subscriptions = {};
export const helpers = {
    updateStateToObject(state, object, id) {
        if (Array.isArray(object)) {
            object.forEach(element => {
                const stateElement = state[element[id]];
                const shouldSet = stateElement === undefined ||
                    !this.compareObjects(stateElement, element);
                if (shouldSet) {
                    state[element[id]] = element; // TOMC: REACTIVE
                }
            });
            Object.values(state).forEach(element => {
                // @ts-ignore
                if (!object.find(newel => newel[id] === element[id])) {
                    // @ts-ignore
                    delete state[element[id]]; // TOMC: REACTIVE
                }
            });
        }
    },
    // eslint-disable-next-line complexity
    compareObjects(object1, object2) {
        if (object1 === null || object2 === null)
            return object1 === object2;
        // TODO replace by lodash
        if (typeof object1 !== 'object' || typeof object2 !== 'object') {
            return object1 === object2;
        }
        if (typeof object1 !== typeof object2)
            return false;
        for (const p in object1) {
            // Check property exists on both objects
            if (object1.hasOwnProperty(p) !== object2.hasOwnProperty(p)) {
                return false;
                // @ts-ignore
            }
            else if (typeof (object1[p]) == 'object') {
                // @ts-ignore
                if (!this.compareObjects(object1[p], object2[p])) {
                    return false;
                }
                // @ts-ignore
            }
            else if (object1[p] !== object2[p]) {
                return false;
            }
        }
        if (typeof object1 === 'object' && typeof object2 === 'object') {
            return this.__secondHasExtraProperties(object1, object2);
        }
        return true;
    },
    __secondHasExtraProperties(object1, object2) {
        for (const p in object2) {
            // @ts-ignore
            if (typeof (object1[p]) === 'undefined') {
                return false;
            }
        }
        return true;
    },
    secondsToTime(sec) {
        let seconds = sec;
        const days = Math.floor(seconds / 3600 / 24);
        seconds -= days * 3600 * 24;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        seconds = Math.floor(seconds);
        if (days > 0) {
            return days + 'd ' + hours + 'h';
        }
        if (hours > 0) {
            return hours + 'h ' + minutes + 'm';
        }
        if (minutes > 0) {
            return minutes + 'm ' + seconds + 's';
        }
        return seconds + 's';
    },
    async subscribe(key, parameters, action) {
        const sleep = parameters.repeat ? parameters.repeat : 10000;
        if (subscriptions[key] === undefined)
            subscriptions[key] = { count: 0, destroy: undefined };
        const subscription = subscriptions[key];
        if (subscription.count === 0) {
            subscription.count++;
            try {
                await action();
            }
            catch (error) {
                console.log('Error Pre', error);
            }
            subscription.destroy = window.setInterval(() => {
                action().catch(error => {
                    console.log('Failed', action, error);
                });
            }, sleep);
        }
        else {
            subscription.count++;
        }
        if (parameters.commit) {
            // depricated, needs to go
            parameters.commit('debug/setSubscriptions', subscriptions, { root: true });
        }
        else {
            store.commit.debug.setSubscriptions(subscriptions);
        }
        return { key, subscription: subscription.count, running: subscription.destroy !== undefined };
    },
    async unsubscribe(key, commit = undefined) {
        let subscription = subscriptions[key];
        if (subscription === undefined) {
            throw new Error(`You should not unsubscribe if you have not subscribed yet ${key}`);
        }
        else if (subscription.count === 0) {
            throw new Error(`You should not unsubscribe if you have not subscribed yet ${key}`);
        }
        else {
            subscription.count--;
            const destroyFunction = subscription.destroy;
            if (destroyFunction !== undefined && subscription.count === 0) {
                window.clearInterval(destroyFunction);
                subscription.destroy = undefined;
                delete subscriptions[key];
            }
            subscription = subscriptions[key];
            if (commit !== undefined) {
                // FIXME is this still necessary?
                commit('debug/setSubscriptions', subscriptions, { root: true });
            }
            else {
                store.commit.debug.setSubscriptions(subscriptions);
            }
            if (subscription !== undefined) {
                return { key, subscription: subscription.count, running: subscription.destroy !== undefined };
            }
            else {
                return { key, subscription: 0, running: false };
            }
        }
    }
};
