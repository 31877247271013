import { API } from '@/BeatoutAPI';
import { DetectedObject } from '@/types/cv';
export default {
    async upload(_context, img) {
        const response = await API.getRequest().post('cv/school/detect/all', { image: img });
        console.log(response);
        const key = Object.keys(response.data)[0];
        if (key === undefined)
            throw new Error('Could not parse response');
        const objects = response.data[key].objects;
        if (objects === undefined)
            return {
                url: '',
                objects: []
            };
        const list = [];
        let url = '';
        objects.forEach(object => {
            list.push(new DetectedObject(object));
            url = object.url;
        });
        return {
            url,
            objects: list
        };
    }
};
