import { helpers } from '../helpers';
import { moduleActionContext } from '@/store';
const state = {
    active: false,
    posts: {}
};
const getters = {};
const mutations = {
    set(rfidstate, post) {
        rfidstate.posts[post.id] = post; // TOMC: REACTIVE
    }
};
const actions = {
    async get(_context, _team) {
        /* const { commit } = instagramModuleActionContext(context)
        const team_id = team || 'all'
        const response = await API.getRequest().get(`instagram/${team_id}`)
        for (const post of response.data.posts) {
          commit.set(post)
        } */
    },
    async subscribe(context, team) {
        const { dispatch } = instagramModuleActionContext(context);
        const team_id = team || 'all';
        await helpers.subscribe(`instagram/${team_id}`, { repeat: 10000 }, async () => {
            dispatch.get(team_id);
        });
    },
    async unsubscribe(_context, team) {
        const team_id = team || 'all';
        await helpers.unsubscribe(`instagram/${team_id}`);
    }
};
const instagramModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default instagramModule;
export const instagramModuleActionContext = (context) => moduleActionContext(context, instagramModule);
