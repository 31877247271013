import { createDirectStore } from 'direct-vuex';
import teams from './modules/teams';
import rooms from './modules/rooms';
import clock from './modules/clock';
import rfid from './modules/rfid';
import auth from './modules/auth';
import users from './modules/users';
import hardware from './modules/hardware';
import software from './modules/software';
import debug from './modules/debug';
import files from './modules/files';
import settings from './modules/settings';
import instagram from './modules/instagram';
import schedule from './modules/schedule';
import cv from './modules/cv';
import location from './modules/location';
const indebug = false;
const { store, rootActionContext, moduleActionContext } = createDirectStore({
    modules: {
        teams,
        rooms,
        clock,
        rfid,
        auth,
        users,
        files,
        hardware,
        software,
        debug,
        instagram,
        settings,
        schedule,
        cv,
        location
    },
    strict: indebug
});
store.commit.clock.update();
window.setInterval(() => {
    store.commit.clock.update();
}, 1000);
export { rootActionContext, moduleActionContext };
export default store;
