import { DutchTranslation } from "./dutch";
import { FrenchTranslation } from "./french";
import { EnglishTranslation } from "./english";
export var BeatoutLanguage;
(function (BeatoutLanguage) {
    BeatoutLanguage["Dutch"] = "dutch";
    BeatoutLanguage["English"] = "english";
    BeatoutLanguage["French"] = "french";
})(BeatoutLanguage || (BeatoutLanguage = {}));
export const translations = {
    [BeatoutLanguage.Dutch]: DutchTranslation,
    [BeatoutLanguage.English]: EnglishTranslation,
    [BeatoutLanguage.French]: FrenchTranslation
};
