import { API } from "@/BeatoutAPI";
import { locations } from "@/types/location";
import { pickBy } from 'lodash';
import { locationModuleActionContext } from ".";
// @ts-ignore
import Cookies from 'js-cookie';
const actions = {
    async changeLocation(context, locationId) {
        console.log('set location', locationId);
        const { commit } = locationModuleActionContext(context);
        commit.setLocation(locationId);
        API.changeApiUrl(locations[locationId].apiurl);
        Cookies.set('location', locationId);
    },
    async loadLocation(context) {
        const { dispatch } = locationModuleActionContext(context);
        const locationId = Cookies.get('location');
        console.log('location loaded', locationId);
        const currentLocation = pickBy(locations, (loc) => loc.apiurl === window.location.hostname);
        const currentLocationId = Object.keys(currentLocation)[0];
        if (currentLocationId) {
            await dispatch.changeLocation(currentLocationId);
        }
        if (locationId) {
            await dispatch.changeLocation(locationId);
        }
    }
};
export default actions;
