/* eslint-disable complexity */
import { Sensor, IfThisThenThat } from ".";
export class RPiConfig {
    constructor(data = undefined) {
        this.id = '';
        this._id = ''; // depricated
        this.name = '';
        this.message_channels = [];
        this.sensors = [];
        this.serveraddress = null;
        this.alwaysonline = false;
        this.ittt = [];
        this.audiooutput = 2;
        this.vpn = 'unknown';
        this.last_ip = 'unknown';
        if (data !== undefined)
            this.load(data);
    }
    load(data) {
        var _a;
        if (typeof data !== 'object')
            throw new Error('RPiConfig load: data is not an object');
        this.id = typeof data._id === 'string' ? data._id : '';
        if (data.id)
            this.id = data.id;
        this._id = this.id;
        this.name = typeof data.name === 'string' ? data.name : '';
        this.message_channels = data.message_channels !== undefined ? data.message_channels : [];
        this.sensors = [];
        if (Array.isArray(data.sensors)) {
            data.sensors.forEach((sensor) => {
                this.sensors.push(new Sensor(sensor));
            });
        }
        this.serveraddress = (_a = data.serveraddress) !== null && _a !== void 0 ? _a : null;
        this.alwaysonline = typeof data.alwaysonline === 'boolean' ? data.alwaysonline : false;
        this.ittt = [];
        if (Array.isArray(data.ittt)) {
            data.ittt.forEach((ittt) => {
                this.ittt.push(new IfThisThenThat(ittt));
            });
        }
        this.audiooutput = typeof data.audiooutput === 'number' ? data.audiooutput : 2;
        this.vpn = typeof data.vpn === 'string' ? data.vpn : 'unknown';
        this.last_ip = typeof data.last_ip === 'string' ? data.last_ip : 'unknown';
    }
}
