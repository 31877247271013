export class Settings {
    constructor(data) {
        this.enable_timeslots = false;
        this.timeslots = [];
        if (typeof data.enable_timeslots === 'boolean')
            this.enable_timeslots = data.enable_timeslots;
        if (data.timeslots)
            this.timeslots = data.timeslots;
    }
}
