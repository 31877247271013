export var ButtonColors;
(function (ButtonColors) {
    ButtonColors["Red"] = "red";
    ButtonColors["Green"] = "green";
    ButtonColors["Blue"] = "blue";
    ButtonColors["Yellow"] = "yellow";
})(ButtonColors || (ButtonColors = {}));
export var GameState;
(function (GameState) {
    GameState["Stopped"] = "stopped";
    GameState["Introduction"] = "introduction";
    GameState["Pregame"] = "pregame";
    GameState["Playing"] = "playing";
    GameState["PostGame"] = "postgame";
    GameState["Testing"] = "testing";
})(GameState || (GameState = {}));
export var GameType;
(function (GameType) {
    GameType["Unknown"] = "";
    GameType["Laser"] = "laser";
    GameType["Reaction"] = "reaction";
    GameType["Combination"] = "combination";
    GameType["Cluedo"] = "cluedo";
    GameType["Catapult"] = "catapult";
    GameType["Keypad"] = "keypad";
    GameType["Camera"] = "camera";
    GameType["Arcade"] = "arcade";
    GameType["SpiderWeb"] = "spiderweb";
    GameType["Repeat"] = "repeat";
    GameType["Jungle"] = "jungle";
})(GameType || (GameType = {}));
export var SchedulePriority;
(function (SchedulePriority) {
    SchedulePriority["Important"] = "important";
    SchedulePriority["Normal"] = "normal";
    SchedulePriority["Minor"] = "minor";
    SchedulePriority["Never"] = "never";
})(SchedulePriority || (SchedulePriority = {}));
export var RoomStatusMessageCode;
(function (RoomStatusMessageCode) {
    RoomStatusMessageCode["UNKNOWN"] = "unknown";
    RoomStatusMessageCode["TOO_CLOSE_TO_END"] = "too_close_to_end";
    RoomStatusMessageCode["WRONG_ROOM"] = "wrong_room";
})(RoomStatusMessageCode || (RoomStatusMessageCode = {}));
