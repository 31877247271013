import { createApp } from 'vue';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import "bootstrap";
// @ts-ignore
import { Vue3Mq } from 'vue3-mq';
import VueApexCharts from "vue3-apexcharts";
/* if (process.env.NODE_ENV !== 'development') {
  store.dispatch.debug.enable()
} */
const toastOptions = {
    position: POSITION.TOP_CENTER
};
const app = createApp(App);
app.use(store.original)
    .use(Toast, toastOptions)
    .use(router)
    .use(Vue3Mq)
    .use(VueApexCharts)
    .mount('#app');
if (process.env.NODE_ENV === 'development') {
    console.log('Enable development tools');
    app.config.performance = true;
}
