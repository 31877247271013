import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { moduleActionContext } from '@/store';
const settingsModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default settingsModule;
export const settingsModuleActionContext = (context) => moduleActionContext(context, settingsModule);
